import React from 'react';

import './projectspage.css';

const ProjectsPage = () => <div className="projects-page subpages">
	<h1>Projects</h1>

	<h2>Major Projects</h2>
	<section>
		<h3>Murder Mystery</h3>
		A PIXIjs spot-the-liar style murder mystery. Go from room to room collecting testimonies from suspects.<br/><br/>
		<span className="detail-links">
			<a href="https://github.com/gracefuljs/murder-mystery" target="_blank" rel="noreferrer noopener">[Github Repo]</a> 
			<a href="https://gracefuljs.github.io/murder-mystery/" target="_blank" rel="noreferrer noopener">[Live Project]</a>
		</span>
	</section>

	<section>
		<h3>Fantasy World Explorer</h3>
		A world viewer powered by THREE.js. Spin the globe to see all the continents.<br/><br/>
		<span className="detail-links">
			<a href="https://github.com/gracefuljs/conworld-projects-planet" target="_blank" rel="noreferrer noopener">[Github Repo]</a> 
			<a href="https://github.com/gracefuljs/conworld-projects-planet" target="_blank" rel="noreferrer noopener">[Project]</a>
		</span>
	</section>

	<h2>Code Snippets</h2>
	Uploading soon...

</div>

export default ProjectsPage;