import React, {useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import './Site.css';

//Components - Pages
import MainMenu from './main-menu/mainmenu';
import HomePage from './home-page/homepage';
import AboutPage from './about-page/aboutpage';
import ProjectsPage from './projects-page/projectspage';
import BlogPage from './blog-page/blogpage';

//Components - Other
import SideDrawer from './main-menu/side-drawer/sidedrawer';
import BackDrop from './back-drop/backdrop';


const Footer = () => <footer>Copyright © 2020 gracefulJS</footer>;

export default function Site(){
	const [sideDrawOpen, setDrawerOpen] = useState(false);

    const drawerToggleClickHandler = () => {
        setDrawerOpen(!sideDrawOpen);
    }

    const closeDrawer = () => {
        setDrawerOpen(false);
    }

    let backdrop = ( sideDrawOpen ) ? <BackDrop click={closeDrawer} /> : null;

	return (<div className="site">
			
			<Router>
				<MainMenu drawerClickHandler={drawerToggleClickHandler}/>
				<SideDrawer menuClickHandler={closeDrawer} show={sideDrawOpen}/>
        		{backdrop}
				
				<Switch>
					<Route path="/about">
						<AboutPage/>
					</Route>

					<Route path="/Projects">
						<ProjectsPage/>
					</Route>

					<Route path="/blog">
						<BlogPage/>
					</Route>

					<Route exact path="/">
						<HomePage/>
					</Route>


				</Switch>
			</Router>
			
			<Footer/>
		</div>)
}