//JS Modules
import React from 'react';

//Static Files
import './backdrop.css';

const Backdrop = (props) => (
	<div className="backdrop" onClick={props.click}></div>
);

export default Backdrop;