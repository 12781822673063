import React from 'react';

//Static Files
import "./hamburger.css";


const HamburgerButton = (props) => (
	<button className = "hamburger-button" onClick={props.click}>
		<div className="hamburger-line"></div>
		<div className="hamburger-line"></div>
		<div className="hamburger-line"></div>
	</button>
);

export default HamburgerButton;