import React from 'react';

import './aboutpage.css';

const AboutPage = () => <div className="about-page subpages">
	<h1>About Me</h1>
	Hi, I'm Grace, the nerdy two-tailed monster cat with a penchant for fictional worlds and using programming to flesh them out. Even though I've been using Javascript since I was a kid, it's only recently that I decided to take it to the next level. While my visual story-telling skills don't amount to much, I think that my programming skills should come in handy for anyone interested.<br/><br/>

	My area of expertise lies in WebGL-based libraries such as PIXI, and Javascript in general. I have full-stack experience, especially MERN. I'm not much of a graphic artist, but I'm working hard to fix that!
</div>

export default AboutPage;