import React from 'react';
import './homepage.css';

const Hero = () => <div className="hero">
	<p className="tag">World Building Powered by Javascript</p>
	<p className="sub-tag">A collection of front-end projects and games taking place in the same, loosely connected world.</p>
</div>;
 
const Card = ({title, description}) => <div className="card">
	<h4>{title}</h4>
	<p>{description}</p>
</div>

const Intro  = () => <div className="intro">
	Learning is fun, but it can also be difficult. So, how can you push through all of the mental barriers to stick with it? Make it meaningful! How do you do that? Link it to your passion! This is what I did. Worldbuilding is my favorite hobby, and it lends itself perfectly for development. 
	
	<h3>What I'm Currently Working On</h3>

	<ul>
		<li><Card title="WebGL" description="My favorite. Rendering graphics in the browser for games or visual storytelling." /></li>
		<li><Card title="Wordpress" description="35% of websites are built with it, right? So it would be a good idea to learn it." /></li>
		<li><Card title="Advanced React" description="Let's try to make something more robust." /></li>
	</ul>
	
	<ul></ul>

</div>;

const HomePage = () => <div className="home-page">
	<Hero/>
	<Intro/>
</div>

export default HomePage