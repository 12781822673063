import React from 'react';
import { Link } from 'react-router-dom';

import HamburgerButton from './hamburger/hamburger';

import './mainmenu.css';

const MainMenu = ({drawerClickHandler}) => (
	<nav className="nav">
		<div className="hamburger-button">
			<HamburgerButton click={drawerClickHandler}/>
		</div>

		<ul>
			<li className="logo">
				<Link to="/">gracefulJS</Link>
			</li>
			
			<li className="first-link">
				<Link to="/projects">Projects</Link>
			</li>

			<li>
				<Link to="/about">About</Link>
			</li>
			
			<li>
				<Link to="/blog">Blog</Link>
			</li>
		
		</ul>
	</nav>
)

export default MainMenu