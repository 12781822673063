import React from 'react';

import './blogpage.css';

const BlogPage = () => <div className="blog-page subpages">
	<h1>Blog</h1>

	Coming soon!
</div>

export default BlogPage;