//JS Modules
import React from 'react';
import {Link} from 'react-router-dom';

//Static Files
import './sidedrawer.css';

const SideDrawer = (props) => {
	
	let drawerClass = "side-drawer";
	let {menuClickHandler} = props;
	
	if(props.show){
		drawerClass = "side-drawer open"
	}
	
	return (
		<nav className={drawerClass}>
			
			<ul>
				
				<b>Main Menu</b>

				<li onClick={menuClickHandler}>
					<Link to="/">Home</Link>
				</li>
				
				<li onClick={menuClickHandler}>
					<Link to="/projects">Projects</Link>
				</li>

				<li onClick={menuClickHandler}>
					<Link to="/about">About</Link>
				</li>
				
				<li onClick={menuClickHandler}>
					<Link to="/blog">Blog</Link>
				</li>
			
			</ul>
		</nav>
	);
};

export default SideDrawer